.walllet-section{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-bottom: 150px;
    .first-row{
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 45px; 
        margin-bottom: 45px;
    }
    .second-row{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 23%;
    }
}
@media all and (max-width:767px){
    .walllet-section .first-row {
        display: block;
        margin-bottom: 0px;
    }
    .walllet-section .second-row {
        display: block;
    }
    .walllet-section .wallet-option{
        width: 100%;
        height: 100%;
        margin-bottom: 15px;
    }
}