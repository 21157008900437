// .slider-container {
//     margin-top: 20px;
//     width: 80%;

//         height: 600px; /* Set desired height for the swiper */
//         overflow: hidden; /* Ensure content doesn't overflow */
 
      
//       .swiper {
//         height: 100%; /* Match the parent container's height */
//       }
// .cv-card{
//     width: 327.407px;

//     height: 460.012px;
//     display: flex;
//     padding: 14.815px;
//     flex-direction: column;
//     justify-content: top;
//     align-items: flex-start;
//     gap: 14.815px;
//     flex: 1 0;
//     align-self: stretch;
//     border-radius: 14.815px;
//     border: 1.481px solid var(--gradient3, #69EACB);
//     background: linear-gradient(155deg, rgba(255, 255, 255, 0) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
//     box-shadow: 0px 2.963px 36.296px 0px rgba(0, 7, 72, 0.12);
//     -webkit-backdrop-filter: blur(9.2592592239px);
//     backdrop-filter: blur(9.2592592239px);
//     .cp-text-1 {
//         color: #FFF;
//         font-family: Poppins;
//         font-size: 12.37px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: normal;
//     }
// }
// .slick-prev:before, .slick-next:before {
//     content:"";
// }
// .slick-slide {
//     margin: 0 10px; /* Adds space on the left and right */
//   }
  
//   /* Ensure the parent container doesn't add unwanted padding */
//   .slick-list {
//     margin: 0 -10px; /* Counteracts the margin of slides to maintain alignment */
//   }
//   .card-img-div {
//     width: 100%;
//     height: 100%;
// }
// }
// .slider-container {
//     position: relative;
//   }
  
//   .swiper-navigate {
//     // position: absolute;
//     top: 90%;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     transform: translateY(-50%);
//     z-index: 10;
//   }
  
//   .swiper-left,
//   .swiper-right {
//     cursor: pointer;
//   }
  

//   @media all and (max-width: 768px) {
//     .slider-container {
//       width: 100%;
//     }
//     // .slick-slide, .swiper-slide {
//     //     width: 100%; /* Ensure slides take full width */
//     //   }
    
//       .card-img-div img {
//         max-width: 100%; /* Ensure images scale properly */
//         height: auto;
//       }
//       .cv-card{
//         width: 100%;
//       }
//       .slick-slide{
//         width: 390px !important;
//       }
//       .slick-active{
//         width: 100% ;
//       }
//       .cv-card-div{
//         width: 80% !important;
//       }
//       .Slider{
//         width: 100% !important;
//       }
//   }
//   @media all and (max-width: 400px) {
//     .slick-slide{
//         width: 300px !important;
//       }
//   }

  .swiper-navigate {
      position: absolute;
      top: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      transform: translateY(-50%);
      z-index: 10;
    }
    
    .swiper-left,
    .swiper-right {
      cursor: pointer;
    }


    