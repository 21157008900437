/* Custom styles for Slick slider pagination dots */
.slick-dots li button:before {
  font-size: 17px !important;
  width: 17px !important;
  height: 17px !important;
  color: #D9D9D9 !important;
  opacity: 1 !important;
  /* Set the color you want for the dots */

}

.slick-dots li.slick-active button:before {
  color: #2E94CB !important;
  /* Set the color you want for the active dot */
}

.mobile-view {
  display: none;
}

.df-jsc-ac {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.topic-heading {
  background: var(--gradient3, linear-gradient(90deg, #69EACB 40%, #EACCF8 48%, #6654F1 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: "Clash Display";
  font-size: 64.444px;
  font-style: normal;
  font-weight: 600;
  line-height: 58.148px;
  /* 90.23% */
  letter-spacing: 1.611px;
  text-transform: capitalize;
  margin-bottom: 50px !important;
}

.road-map-bg {
  border-radius: 29.63px;
  border-top: 1.111px solid #FFF;
  border-right: 1.111px solid #FFF;
  border-bottom: 1.111px solid #FFF;
  /* opacity: 0.1; */
  background: linear-gradient(105deg, rgba(255, 255, 255, 0.11) -3.07%, rgba(255, 255, 255, 0.11) 49.04%, rgba(255, 255, 255, 0.00) 97.84%);
  box-shadow: 0px 2.963px 4.444px 0px rgba(0, 0, 0, 0.25);
}

.stake-bg {
  background-image: url(/src/assets/images/stackbg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bluegradient {
  background-image: url(/src/assets/images/bluegradientbanner.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.all-gradient {
  background-image: url(/src/assets/images/allgradients.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blur-effect {
  background: rgba(255, 255, 255, 0.05);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.counter-div {
  font-family: "Clash Display" !important;
}

.counter-div::after {
  content: ":";
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width:767px) {
  .mobile-view {
    display: block;
  }

  .desktop-view {
    display: none;
  }

  .topic-heading {
    font-size: 29px;
    margin-bottom: 0 !important;
  }

}