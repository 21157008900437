.footer {
    .footer-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .footer-img-main-div {
            background-image: url(../../assets/images/footerborder.svg);
            background-position: top center;
            /* Center aligns the image horizontally */
            background-repeat: no-repeat;
            background-size: cover;
            /* Ensures the image covers the container proportionally */
            width: 80%;
            /* Full width */
            height: auto;
            /* Let height adjust automatically unless a specific height is required */
            min-height: 200px;
            /* Optional: Define a minimum height for consistent display */
            overflow: hidden;
            /* Prevents any overflow issues if the image doesn't fit */
        }

        .main-social-div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 50px 100px;

            .social-div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                .follow {
                    color: #FFF;
                    font-family: Arial;
                    font-size: 11.852px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .connect-btn {
                color: #FFF;
                font-family: Arial;
                font-size: 11.852px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                width: 128.889px;
                height: 35.556px;
                flex-shrink: 0;
                border-radius: 17.407px;
                background: linear-gradient(66deg, #00C3FD 25.98%, #0194FE 65.48%);
                box-shadow: 0px 11.852px 22.222px 0px #003861;
            }

        }

        .copy-right-div {
            width: 100%; /* Make it responsive across all screen sizes */
            max-width: 1324.444px; /* Retain your defined width as the maximum limit */
            height: 32.222px; /* Fixed height as per your requirement */
            flex-shrink: 0; /* Prevent the element from shrinking */
            background: linear-gradient(90deg, #141843 2.31%, #00C3FD 47.25%, #141843 99.25%); /* Corrected `fill` to `background` */
            z-index: 1; /* Ensure it's layered above lower z-index elements */
            position: relative; /* Keeps it positioned relative to parent container */
            margin: 0 auto; /* Center aligns the div horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
            p {
                color: #000618;
                font-family: Inter;
                font-size: 14.37px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}
@media all and (max-width:767px){
    .footer .footer-content .main-social-div {
        display: block;
        padding: 0;
    }
    .mob-footer{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }
}