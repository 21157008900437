.cv-card {
    margin: 0 auto;
    padding: 14.815px;
    width: 327.407px;
    height: 468.012px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: flex-start;
    gap: 14.815px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 14.815px;
    border: 1.481px solid var(--gradient3, #69EACB) !important;
    background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%) !important;
    box-shadow: 0px 2.963px 36.296px 0px rgba(0, 7, 72, 0.12) !important;
    backdrop-filter: blur(9.259259223937988px) !important;
}

.card-img-div {
    width: 296px;
    height: 316px;
}

.idea-img {
    width: 100%;
    height: 100%;
}

.cp-text-1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 12.37px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media all and (max-width: 767px) {
    .cv-card {
        padding: 15px;
    }

    .cp-text-1 {
        font-size: 14px;
    }
}
