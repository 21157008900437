.vision-mission {
.vission-mission-content{

    padding: 117.938px 0px 26.507px 0px;
}
    .vision-head {
        margin-top: 59.63px;
        h1 {
            font-size: 64.444px;
            font-style: normal;
            font-weight: 600;
            line-height: 58.148px;
            /* 90.23% */
            letter-spacing: 1.611px;
            text-transform: capitalize;
            background: var(--gradient3, linear-gradient(90deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width: 627.407px;
       
        }

        p {
            color: #FFF;
            font-family: Poppins;
            font-size: 14.852px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            width: 100%;
        }
    }

    .banner-btns-div {
        display: flex;
        gap: 10.407px;
        flex-shrink: 0;
        margin-top: 50px;

        .whitepaper-btn {
            display: flex;
            width: 153.969px;
            height: 45.926px;
            padding: 11.111px 40.741px;
            justify-content: center;
            align-items: center;
            gap: 7.407px;
            flex-shrink: 0;
            border-radius: 44.444px;
            background: var(--Blue-1, #2F80ED) ;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-color: transparent;
        }

        .get-btn {
            display: flex;
            width: 186.044px;
            height: 45.926px;
            padding: 11.111px 40.741px;
            justify-content: center;
            align-items: center;
            gap: 7.407px;
            flex-shrink: 0;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 44.444px;
            border: 0.741px solid #FFF;
            opacity: 0.9;
            background: transparent;
        }
    }
}
@media all and (max-width:767px){
    .vision-mission .vission-mission-content {
        padding: 0;
    }
    .vision-mission .vision-head h1 {
        font-size: 35.444px;
        line-height: 41.148px;
    }
    .vision-mission .vision-head p {
        color: #FFF;
        font-family: Poppins;
        font-size: 12.852px;
    }   
    .vision-mission .banner-btns-div .whitepaper-btn{
        width: 40%;

    } 
    .vision-mission .banner-btns-div  .get-btn {
        width: 45%;

    }
    .vision-mission .main-form-div {
        margin-top: 58px;
    }
}