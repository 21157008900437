
.collect-raised-div {
    display: flex;
    align-items: center;
    margin-top: 35px;
    gap: 45px;
    padding-left: 20px;

    .view-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        h4 {
            color: #FFF;
            // font-family: "Clash Display";
            font-size: 26.667px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 100%;
            height: 33.427px;
            flex-shrink: 0;
        }

        p {
            color: #FFF;
            font-family: Inter;
            font-size: 11.852px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
   }
   .bluegradient{
    margin-top: 50px;
}
    .home-banner-video {

        position: relative;
        width: 100%;
        height: 100%;
        /* Full viewport height */
        overflow: hidden;
    
        /* Video styling */
        .bg-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            /* Ensure the video covers the area */
            // z-index: -1;
            filter: brightness(50%);
            /* Adjust brightness: lower percentage = darker */
        }
    }
    #header-bg{
        background: #d4d9db24 !important; /* Semi-transparent background */
        backdrop-filter: blur(10px) !important;  
        /* -webkit-backdrop-filter: blur(10px);
        blur-filter: blur(10px); */
      }

    @media all and (max-width: 768px) {
        .home-banner-video {
            height: 100%;
        }
        .bluegradient{
            margin-top: 0px;
        }
        .bg-video{
            height: 75vh;
            object-fit: cover;
        }
    }