.img-div {
    position: relative !important;

    .monkey-img {
        // width: 100%;
        height: 100%;

    }

    .live-div {
        display: flex;
        width: 149.155px;
        height: 54.805px;
        padding: 15.86px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5.86px;
        flex-shrink: 0;
        border-radius: 11.895px;
        border: 0.793px solid #56527c;
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        box-shadow: 0px 3.172px 38.856px 0px rgba(0, 7, 72, 0.12);
        backdrop-filter: blur(9.91235637664795px);
        position: absolute;
        top: 18.5%;
        right: 4%;
        transform: skewX(-13deg);

        h4 {
            color: #FFF;

            font-size: 19.032px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            color: #24FF00;

            font-size: 11.102px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 3.442px;
        }
    }





    .prime-div {
        display: flex;
        width: 256.808px;
        height: 74.106px;
        padding: 12.079px 7.146px 12.079px 15.693px;
        // justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15.86px;
        border: 0.793px solid #56527c;
        background: linear-gradient(155deg, rgba(252, 255, 117, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        backdrop-filter: blur(18.238737106323242px);
        position: absolute;
        top: 49%;
        right: 3%;
        transform: skewX(-13deg);

        h4 {
            color: #FFF;

            font-size: 19.032px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            color: #24FF00;
            // font-family: "Clash Display" !important;
            font-size: 11.102px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 3.442px;
        }

        .monkey-logo {
            object-fit: contain
        }
    }

    .stage-div {
        display: flex;
        width: 286.842px;
        height: 78.239px;
        padding: 15.86px;
        // flex-direction: column;
        justify-content: flex-start !important;
        align-items: center;
        gap: 15.86px;
        flex-shrink: 0;
        border-radius: 15.86px;
        border: 0.793px solid #56527c;
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        box-shadow: 25.376px 22.997px 38.856px 0px rgba(0, 7, 72, 0.08);
        backdrop-filter: blur(18.238737106323242px);
        position: absolute !important;
        top: 59%;
        right: 48.5%;
        transform: skewX(-13deg);

        h4 {
            color: #FFF;
            font-size: 19.032px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .stage-raised {
            color: #FFF !important;
            font-size: 11.102px !important;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 78.506px;
            text-align: left;
        }

        p {
            color: #24FF00;
            text-align: right;
            font-size: 11.102px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .price-div {
        display: flex;
        width: 100.093px;
        height: 66.121px;
        padding: 15.86px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15.86px;
        flex-shrink: 0;
        border-radius: 15.86px;
        border: 0.793px solid #56527c;
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        box-shadow: 0px 3.172px 38.856px 0px rgba(0, 7, 72, 0.12);
        backdrop-filter: blur(9.91235637664795px);
        position: absolute;
        top: 72%;
        right: 58%;
        transform: skewX(-13deg);

        h4 {
            color: #FFF;
            font-size: 15.86px;
            font-style: normal;
            font-weight: 600;
            line-height: 14.815px;
            /* 93.411% */
            width: 71.678px;
        }

        span {
            color: #FFF;
            // font-family: "Clash Display";
            font-size: 11.102px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 78.506px;
        }
    }

    .timing-div {
        display: flex;
        width: 340.308px;
        height: 78.782px;
        padding: 15.86px;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15.86px;
        flex-shrink: 0;
        border-radius: 15.86px;
        border: 0.793px solid #56527c;
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        box-shadow: 0px 3.172px 38.856px 0px rgba(0, 7, 72, 0.12);
        backdrop-filter: blur(9.91235637664795px);
        color: #FFF;
        position: absolute;
        top: 74%;
        right: 0.5%;
        transform: skewX(-13deg);

        .day-div {
            display: flex;
            width: 41.842px;
            height: 100%;
            // padding: 15.86px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            // gap: 15.86px;
            flex-shrink: 0;
            border-radius: 15.86px;

            h4 {
                color: #FFF;
                // font-family: "Clash Display";
                font-size: 25.376px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: 81.678px;
            }

            p {
                color: #FFF;
                // font-family: "Clash Display";
                font-size: 11.102px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                width: 78.506px;
            }
        }



    }

    @media all and (max-width:1690px) {
        .live-div {
            top: 18%;
            right: 6%;
        }
    }

    @media all and (max-width:1400px) {
        .live-div {
            top: 18%;
            right: 2%;
        }
        .prime-div {
            right: -4%;

        }
        .price-div{
            right: 55%;
        }
        .timing-div{
            right: -10.5%;
        }
    }

    @media all and (max-width:1200px) {
        .live-div {
            top: 14%;
            right: -2%;
        }
        .prime-div {
            right: -3%;
            width: 213.808px;
    height: 63.106px;
        }
        .timing-div {
            right: -8.5%;
            width: 261px;
            height: 57px;
            .day-div{
                    width: 28.842px;
                h4{
                    font-size: 19px;
                }
            } 
        }
        .stage-div {
            width: 249.842px;
            height: 63.239px;
            h4{
                font-size: 16px;
            }
            p{
                font-size: 9px;
            }
        }
      .price-div {
            right: 52%;
        }
    }

    @media all and (max-width:991px) {
        .live-div {
            top: 18%;
            right: 8%;
        }
        .price-div {
            right: 59%;
        }
        .timing-div {
            right: 17.5%;
            width: 274px;
            height: 64px;
        }
      .prime-div {
            right: 17%;
            width: 190.808px;
            height: 60.106px;
        }
    }
}


@media all and (max-width:767px) {

    .img-div {
        .monkey-img {
            width: 100% !important;
        }

        .live-div {
            display: flex;
            width: 112.155px;
            top: 16%;
            right: 6%;

            h4 {
                font-size: 14.032px;
            }

            p {

                font-size: 6.102px;
            }

        }

        .prime-div {
            width: 153.808px;
            height: 55.106px;
            padding: 12.079px 1.146px 12.079px 8.693px;
right: 15%;
            h4 {
                font-size: 14.032px;
            }

            p {

                font-size: 6.102px;
            }
        }

        .stage-div {

            width: 192.842px;
            height: 57.239px;
            padding: 6.86px;

            gap: 0px;

            h4 {
                font-size: 12.032px;
            }

            p {

                font-size: 6.102px;
            }

            .stage-raised {
                font-size: 9px;
            }
        }

        .price-div {
            display: flex;
            width: 85.093px;
            height: 44.121px;
            padding: 8.86px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15.86px;
            top: 73%;
            right: 57%;

            h4 {
                font-size: 10.032px;
            }

            p {

                font-size: 9.102px;
            }
        }

        .timing-div {
            display: flex;
            width: 180.308px;
            height: 61.782px;
            padding: 8.86px;
            align-items: center;
            justify-content: start;
            gap: 14.86px;
            top: 75%;
            right: 17.5%;
            .day-div {
                width: 11.842px;

                h4 {
                    font-size: 14px;
                }

                p {
                    font-size: 7px;
                }
            }
        }

    }

}

@media all and (max-width:460px) {

    .img-div {
        .live-div {
            display: flex;
            width: 89.155px;
            top: 16%;
            right: 6%;

            h4 {
                font-size: 11px;
            }

        }
        .stage-div {
             width: 169.842px;
             height: 46.239px;
             h4{
                 font-size: 10px;
             }
             .stage-raised {
                 color: #FFF !important;
                 font-size: 8.102px !important;
             }
         }
         .price-div{
            right: 62%;
         }
       .timing-div {
        right: -5.5%;
        width: 68%;
         }
       .prime-div {
            width: 144.808px;
            height: 45.106px;
            padding: 5px;
            right: 7%;
            h4{
                font-size: 11px;
            }
         }
    }
}
